<template>
    <div>
        <div class="filter-panel">
            <CSSelect style="margin-right: 20px">
                <select
                    v-model="timeSelect"
                    style="color: #999;width:118px;height:30px"
                    :style="{
                        color: timeSelect != '' ? '#000' : '#999',
                    }"
                >
                    <option value="" >自定义</option>
                    <option
                        v-for="(state, key) in timeSelectList"
                        :key="key"
                        :value="key"
                        style="color: #999"
                    >
                        {{ state }}
                    </option>
                </select>
            </CSSelect>
            <CSSelect style="margin-right: 0px">
                <el-date-picker
                    :disabled="timeSelectEnable"
                    v-model="startDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    :picker-options="{disabledDate: elDisabledDate}"
                    placeholder="请选择开始时间"
                    style="width: 140px;height:30px"
                    prefix-icon="el-icon-time"
                    :editable="false"
                ></el-date-picker>
            </CSSelect>
            <span class="inline-gray"></span>
            <CSSelect style="margin-right: 30px">
                <el-date-picker
                    :disabled="timeSelectEnable"
                    v-model="endDate"
                    type="date"
                    :picker-options="{disabledDate: elDisabledDate}"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择结束时间"
                    style="width: 140px;height:30px"
                    prefix-icon="el-icon-time"
                    :editable="false"
                ></el-date-picker>
            </CSSelect>
            <button class="btn btn-primary" @click="getData()">查询</button>
        </div>
        <div class="result-panel data" :style="{height:viewPortHeight - 90 + 'px', 'overflow-y': 'auto'}">
          <div class="chart-view" id="chart">

          </div>
        </div>
    </div>
</template>
<script>
import CSSelect from '@/components/common/CSSelect';
import dayjs from 'dayjs';
import {
    appletsDataUrl,
} from '@/requestUrl';

export default {
    name: "appletsData",
    components:{
        CSSelect
    },
    created(){
        window.addEventListener("keydown",this.getDataDown);
    },
    beforeDestroy(){
        window.removeEventListener("keydown",this.getDataDown);
    },
    deactivated(){
         window.removeEventListener("keydown",this.getDataDown);
    },
    data(){
        return {
            viewPortHeight: 500,
            timeSelect:"1",//所选查询日期
            timeSelectEnable:true,//日期是否可选
            timeSelectList:{
                1:"近7天",
                2:"近14天",
                3:"近30天",
            },
            startDate:dayjs().add(-7,"day").format("YYYY-MM-DD"),
            endDate:dayjs().subtract(1, 'day').format("YYYY-MM-DD"),
            charData:{
                title:["1","2","3","4"],
                data:{
                    "访问人数":[10,20,40,10],
                    "新增访问人数":[4,19,2,9,18],
                    "访问次日留存人数":[2,5,3,17,48]
                }
            }
        }
    },
    watch:{
        timeSelect:function(val){
            if(val==""){
                this.timeSelectEnable=false;
            }else{
                this.timeSelectEnable=true;
                let day=7;
                switch(val){
                    case "2":
                        day=14;
                        break;
                    case "3":
                        day=30;
                        break;
                }
                this.endDate=dayjs().subtract(1, 'day').format("YYYY-MM-DD");
                this.startDate=dayjs().subtract(day,'day').format("YYYY-MM-DD");
            }
        }
    },
    mounted(){

      this.viewPortHeight = document.querySelector('.page-content-view').clientHeight;
      this.getData();
      window.onresize = () => {
        if (this.$route.name === 'appletsData') {
          this.viewPortHeight = document.querySelector('.page-content-view').clientHeight;
          this.getData();
        }
      }
    },
    methods:{
        getDataDown(e){
            if(e.keyCode == 13){
                this.getData();
            }
        },
        elDisabledDate(date) {
          return dayjs(date).isAfter(dayjs().subtract(1, 'day'));
        },
        getData(){
            const startDate=dayjs(this.startDate).format("YYYYMMDD");
            const endDate=dayjs(this.endDate).format("YYYYMMDD");
            this.$fly.post(appletsDataUrl,{
                startDate,
                endDate
            })
            .then((res) => {
                if (res.code != 0) {
                    return;
                }
                this.buildChart(res.data);
            });
        },
        buildChart(chartData){
            // var keys=Object.keys(chartData.data);
            var keys=["新增访问人数","次日新增访问留存人数","访问人数","次日访问留存人数"];
            var series=[];
            for(let key in keys){
                series.push({
                    name: keys[key],
                    type: 'line',
                    smooth: false,//控制是否是曲线
                    data: chartData.data[keys[key]]
                });
            }
            console.log(series);
            var echarts=this.$echarts.init(document.getElementById("chart"));
            var option = {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: keys,
                    top:20
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: chartData.title
                },
                yAxis: {
                    type: 'value'
                },
                series: series
            };
            echarts.setOption(option);
        }
    }
}
</script>
<style>
.chart-view {
  width: 100%;
  height:100%;
  min-width: 1000px;
}
</style>
